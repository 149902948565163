import React from 'react';
import PropTypes from 'prop-types';
import BlogPostThumbnail from './BlogPostThumbnail';

const BlogPostsGrid = ({ posts, className }) => {
  return(
    <div className={`blog-posts-list columns is-variable is-4 is-multiline ${className}`}>
      {posts.map((post, index) => <BlogPostThumbnail key={index} post={post} />)}
    </div>
  );
};

BlogPostsGrid.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

export default BlogPostsGrid;
