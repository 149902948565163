import React, { useEffect, useRef, useState } from 'react';
import { bool, element, func, number } from 'prop-types';
import useObserver from '../../hooks/use-observer';

const InfiniteScroll = ({ dataLength, next, hasMore, disabled = false, children }) => {
  const [, setElements, entries] = useObserver({ root: null, rootMargin: '20px', threshold: 1.0 });
  const loader = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setElements([loader.current]);
  }, [loader, setElements]);

  useEffect(() => {
    if (disabled || loading || !hasMore) return;

    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setLoading(true);
        next();
      }
    });
  }, [entries, loading, hasMore, next, disabled]);

  useEffect(() => {
    setLoading(false);
  }, [dataLength]);

  return (
    <>
      {children}
      <div className="load-more" ref={loader}/>
    </>
  );
};

InfiniteScroll.propTypes = {
  dataLength: number,
  next: func.isRequired,
  hasMore: bool.isRequired,
  disabled: bool.isRequired,
  children: element.isRequired,
};

export default InfiniteScroll;
