import React from 'react';
import { shape, string } from 'prop-types';
import { Link } from 'gatsby';

import { MediaImageType } from '../../../types';
import MediaImage from '../../Elements/MediaImage';

const FeaturedBlogPostThumbnail = ({ post }) => {
  return (
    <div className='featured-blog-post-thumbnail post-box'>
      {post?.relationships?.image?.relationships && <div className="header-post">
        <Link className="img-wrapper" to={post.path.alias} ><MediaImage media={post.relationships.image.relationships?.image} alt={post.relationships.image.media?.alt} title={post.relationships.image.media?.title} /></Link>
      </div>}
      <div className="content-post">
        <div className="inner">
          <h2><Link to={post.path.alias}>{post.title}</Link></h2>
          {post?.body?.summary && <p dangerouslySetInnerHTML={{ __html: post.body.summary }}></p>}
        </div>
      </div>
      <div className="footer-post">
        <div className="author">
          <div className="tile is-parent post-info">
            {post?.relationships?.author?.name && <div className="author-name">{post.relationships.author.name}</div>}
            <div className="publish-date">{post.created}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedBlogPostThumbnail.propTypes = {
  post: shape({
    title: string,
    body: shape({
      summary: string
    }),
    path: shape({
      alias: string
    }),
    created: string,
    relationships: shape({
      author: shape({
        name: string,
      }),
      image: MediaImageType
    })
  })
};

export default FeaturedBlogPostThumbnail;
