import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Pager = ({ numPages, currentPage, baseLink }) => {
  if (numPages <= 1) {
    return null;
  }

  return (
    <div className="pager">
      <nav className="pagination is-centered is-rounded is-small" role="navigation" aria-label="pagination">
        <ul className="pagination-list">
          {Array.from({ length: numPages }).map((item, i) => {
            const index = i + 1;
            const link = index === 1 ? baseLink : `${baseLink}/${index}`;
            return(
              <li key={i}>
                <Link to={link}
                  className={'pagination-link ' + (currentPage === index ? 'is-current' : '')}
                  aria-label={'Go to page ' + index}
                  aria-current={currentPage === index ? 'page' : false}
                >
                  {index}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

Pager.propTypes = {
  numPages: PropTypes.number,
  currentPage: PropTypes.number,
  baseLink: PropTypes.string
};

export default Pager;
