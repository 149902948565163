import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { arrayOf, bool, shape, object, string } from 'prop-types';

import InfiniteScroll from '../components/Elements/InfiniteScroll';
import Pager from '../components/Elements/Pager';
import ScrollToTop from '../components/Elements/ScrollToTop';
import Layout from '../components/Layout/Layout';
import BlogPostsGrid from '../components/Sections/Blog/BlogPostsGrid';
import FeaturedBlogPost from '../components/Sections/Blog/FeaturedBlogPost';
import FeaturedBlogPostThumbnail from '../components/Sections/Blog/FeaturedBlogPostThumbnail';
import SEO from '../components/SEO/SEO';
import { MetatagType } from '../types';

import '../fragments/metatags/pageMetatag';
import '../fragments/node/blogPostTeaser';

const BlogPosts = ({ data, pageContext, location }) => {
  const numberOfPages = pageContext.numPages;
  const isFirstPage = pageContext.currentPage === 1;
  const [blogPosts, setBlogPosts] = useState(data?.blogPosts?.nodes || []);
  const [currentPage, setCurrentPage] = useState(pageContext.currentPage);
  const [useInfiniteScroll, setUseInfiniteScroll] = useState(isFirstPage);

  async function loadMorePosts() {
    try {
      const response = await fetch(`/pagination/${btoa(`/blog/${currentPage + 1}`)}.json`);
      const page = await response.json();
      setCurrentPage(currentPage + 1);
      setBlogPosts([...blogPosts, ...(page?.context?.items || [])]);
    } catch (error) {
      setUseInfiniteScroll(false);
      console.error(error);
    }
  }

  const renderFirstPage = () => {
    const featuredBlogsSection = (data?.page?.relationships?.sections || []).find(section => section.type === 'paragraph__blog' && section.active);
    const [mainBlogPost, ...featuredBlogPosts] = featuredBlogsSection?.relationships?.blogposts || [];

    return (
      <>
        <div className="blog-header columns is-variable is-8-tablet">
          <div className="column is-half line-right column-left">
            <div className="first-post">
              {mainBlogPost && <FeaturedBlogPost post={mainBlogPost} />}
            </div>
          </div>
          <div className="column column-right">
            {featuredBlogPosts.map((post, index) => <FeaturedBlogPostThumbnail key={index} post={post} />)}
          </div>
        </div>
        <InfiniteScroll
          dataLength={blogPosts.length}
          hasMore={currentPage !== numberOfPages}
          next={() => loadMorePosts()}
          disabled={!useInfiniteScroll}
        >
          <BlogPostsGrid posts={blogPosts} className="line-top"/>
        </InfiniteScroll>
      </>
    );
  };

  return (
    <Layout className="blog" location={location}>
      <SEO metatags={data.page?.metatags} />
      <section className="section">
        <div className="container">
          {isFirstPage ? renderFirstPage() : <BlogPostsGrid posts={blogPosts} />}
          {!useInfiniteScroll && <Pager numPages={pageContext.numPages} currentPage={pageContext.currentPage} baseLink={'/blog'} />}
        </div>
      </section>
      <ScrollToTop />
    </Layout>
  );
};

export default BlogPosts;

BlogPosts.propTypes = {
  data: shape({
    page: shape({
      title: string.isRequired,
      metatag: arrayOf(MetatagType),
      relationships: shape({
        sections: arrayOf(shape({
          type: string,
          active: bool,
          relationships: shape({
            blogposts: arrayOf(object)
          })
        }))
      })
    }),
    blogPosts: shape({
      nodes: arrayOf(object)
    })
  }),
  pageContext: object,
  location: object
};

export const blogPostsQuery = graphql`
  query blogPostsQuery($skip: Int!, $limit: Int!, $exclude: [Int]) {
    page: nodePage(path: {alias: {eq: "/blog"}}) {
      title
      metatags: metatag_normalized {
        ...PageMetatag
      }
      relationships {
        sections: field_page_sections {
          ...ParagraphBlog
        }
      }
    }
    blogPosts: allNodeBlogPost(
      filter: { drupal_internal__nid: { nin: $exclude } },
      sort: { fields: created, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...NodeBlogPostTeaser
      }
    }
  }
`;
