import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { MediaImageType } from '../../../types';
import MediaImage from '../../Elements/MediaImage';

const BlogPostThumbnail = ({ post }) => {
  return (
    <div className='post-box column is-4'>
      <div className='header-post'>
        <Link className="img-wrapper" to={post.path.alias}><MediaImage media={post.relationships.image.relationships.image} alt={post.relationships.image.media.alt} title={post.relationships.image.media.title} /></Link>
      </div>
      <div className='content-post'>
        <div className='inner'>
          {post?.title && <h3><Link to={post.path.alias}>{post.title}</Link></h3>}
          {post?.body?.summary && <p dangerouslySetInnerHTML={{ __html: post.body.summary }}></p>}
        </div>
      </div>
      <div className="footer-post">
        <div className="author">
          <div className="tile is-parent post-info">
            {post?.relationships?.author?.name && <div className="author-name">{post.relationships.author.name}</div>}
            <div className="publish-date">{post.created}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogPostThumbnail.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.shape({
      summary: PropTypes.string
    }),
    path: PropTypes.shape({
      alias: PropTypes.string
    }),
    created: PropTypes.string,
    relationships: PropTypes.shape({
      author: PropTypes.shape({
        name: PropTypes.string,
      }),
      image: MediaImageType
    })
  })
};

export default BlogPostThumbnail;
