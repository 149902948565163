import { graphql } from 'gatsby';

import '../media/image';

export const fragment = graphql`
  fragment NodeBlogPostTeaser on node__blog_post {
    title
    path {
      alias
    }
    body {
      summary
      processed
    }
    created(formatString: "LL")
    relationships {
      author: field_author {
        name: title
        relationships {
          picture: field_picture {
            ...MediaPicture
          }
        }
      }
      image: field_blog_image {
        ...MediaImage
      }
    }
  }
`;
